<template>
  <div class="product__detail__photo">
    <div class="product__detail__photo__main">
      <video 
        autoplay
        muted
        v-if="currentImg.type.includes('video')" 
        class="photo__main"
        controls
        >
        <source :src="currentImg.src" type="video/mp4"/>
      </video>
      
      <img
        class="photo__main"
        v-else
        :src="
          currentImg.src
            ? currentImg.src
            : 'https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg'
        "
        @click="addImage"
      />
      <div class="product__detail__full_size" v-if="!accepted">
        <input
          type="file"
          name="image-product"
          ref="imageRef"
          @change="onFileChange"
          v-show="false"
        />
        <b-icon class="fullscreen__icon" icon="upload" @click="addImage">
        </b-icon>
      </div>
    </div>

    <div class="product__detail__photo__list" v-if="images.length > 0">
      <div
        class="image__thumb__container"
        v-for="(img, id) in getImages"
        :src="img.src"
        :key="id"
      >

        <video
          autoplay
          muted
          v-if="img.type.includes('video')" 
          class="product__detail__photo__thumbnail"
          @click="changeImage(img)"
          >
          <source :src="img.src" type="video/mp4" />
          No video support.
        </video>
        <img 
          v-else
          :src="img.src" 
          @click="changeImage(img)"
          class="product__detail__photo__thumbnail" />
        <div
          class="delete__icon"
          @click="deleteImage(img, id)"
          v-if="!accepted"
        >
          <b-icon class="delete__image" icon="x"></b-icon>
        </div>
      </div>
    </div>

    <div class="product__detail__photo__acept" v-if="showAcceptButton">
      <button class="accept" @click="emitPhotos">Aceptar</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imagesUplaod: [],
      images: [],
      imageRef: null,
      counter: 0,
      currentImage: { type: 'img' },
      accepted: false,
    };
  },

  methods: {
    addImage() {
      if (this.images.length == 4) {
        return;
      }
      this.$refs.imageRef.click();
    },

    deleteImage(image, idx) {
      if (this.images.length === 1) {
        this.images = [];
        this.imagesUplaod = [];
        this.currentImage = { type: 'img' };
        return;
      }
      this.imagesUplaod = this.imagesUplaod.splice(idx, 1);
      this.images = this.images.filter((i) => i.src != image.src);
    },

    emitPhotos() {
      this.accepted = true;
      this.$emit("photos", this.imagesUplaod);
    },

    changeImage(currentImage) {
      this.currentImage = currentImage
    },

    onFileChange(event) {
      const file = event.target.files[0];
      this.imagesUplaod.push(file);
      const fr = new FileReader();
      (fr.onload = () => {
        this.images.push({
          src: fr.result,
          type: file.type,
        })
        this.currentImage = {
          src: fr.result,
          type: file.type,
        };
      }),
      fr.readAsDataURL(file);
    },
  },

  computed: {
    getImages() {
      return this.images;
    },

    currentImg() {
      return this.currentImage;
    },

    showAcceptButton() {
      return this.images.length >= 2;
    },
  },

  watch: {
    images() {
      if (this.images.length == 0) {
        this.currentImage = { type: 'img'};
        return;
      }
      this.currentImage = this.images[this.images.length - 1];
    },
  },

  created() {
    document.title = "Crear producto";
  },
};
</script>
